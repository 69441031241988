import {
  checkingAgentsInfo,
  checkingPaymentStatus,
  getFlightBookingDetail,
} from 'api/flight';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  flightPaymentMethodCode,
  flyxBookingStatus,
  listEventFlightGtm,
  LIST_SCREEN_STATUS,
  MAX_CALL_API_COUNT,
  paymentStatus,
  TIMEOUT_TIME,
} from 'utils/constants';
import { toast } from 'react-toastify';
import * as gtm from 'utils/gtm';
import cookie from 'js-cookie';
import { CURRENCY_CODE, CURRENCY_PAY } from 'utils/helpersDataHotel';
const TIME_CALL_API_STATUS = 5000;
const pushGtm = (orderDetail, paymentStatus, dataAgents) => {
  if (dataAgents?.agencyCode === 'MYTOUR' && !!paymentStatus && !!orderDetail) {
    const { booking, tickets, payment } = orderDetail;
    const [outboundTicket, inboundTicket] = tickets;
    const dataGtm = {
      device_id: paymentStatus?.transactionInfo?.deviceId || '',
      user_id: '',
      booking_id: booking?.bookingCode || '',
      order_id: paymentStatus?.orderInfo?.id || '',
      outbound_ticket_id: outboundTicket?.id || '',
      inbound_ticket_id: inboundTicket?.id || '',
      from_airport: outboundTicket?.departureAirport || '',
      to_aiport: outboundTicket?.arrivalAirport || '',
      total_price: payment?.totalAmount || '',
      payment_status: Number(
        orderDetail?.booking?.bookingStatus === flyxBookingStatus.SUCCESS
      ).toString(),
      currency: 'VND',
      transaction_id: paymentStatus?.orderInfo?.id || '',
      value: payment?.totalAmount || '',
    };
    gtm.addEventGtm(listEventFlightGtm.flight_purchase, dataGtm);
    gtm.addEventGtm(listEventFlightGtm.purchase, dataGtm);
  }
};

const useFlightBooking = () => {
  const { bookingId } = useParams();
  const location = useLocation();

  const [screenStatus, setScreenStatus] = useState(LIST_SCREEN_STATUS.LOADING);
  const [flyxBooking, setFlyxBooking] = useState();
  const [paymentStatusDetail, setPaymentStatusDetail] = useState();
  const [agentsInfo, setAgentsInfo] = useState();

  const fetchPaymentStatusTimeOutRef = useRef(0);
  const fetchFlyxBookingTimeOutRef = useRef(0);

  useEffect(() => {
    let paymentFetchCount = 0;
    let flyxBookingFetchCount = 0;

    let flyxBookingKey = '';
    let paymentMethod = '';

    const handleSetTimeOutFlyxBooking = (bookingStatus) => {
      if (flyxBookingFetchCount > MAX_CALL_API_COUNT) {
        if (bookingStatus === flyxBookingStatus.IN_PROGRESS)
          setScreenStatus(LIST_SCREEN_STATUS.SUCCESS_AND_EXPORTING);
        else setScreenStatus(LIST_SCREEN_STATUS.UNDEFINED);
      } else {
        fetchFlyxBookingTimeOutRef.current = setTimeout(
          fetchFlyxBooking,
          TIMEOUT_TIME
        );
      }
    };

    const handleSetTimeOutPaymentStatus = async () => {
      if (paymentFetchCount > MAX_CALL_API_COUNT) {
        const { data: bookingDetailRes } = await getFlightBookingDetail({
          bookingKey: flyxBookingKey,
        });
        if (bookingDetailRes.code === 200) {
          setFlyxBooking(bookingDetailRes?.data);
        }
        setScreenStatus(LIST_SCREEN_STATUS.UNDEFINED);
      } else {
        fetchPaymentStatusTimeOutRef.current = setTimeout(
          fetchPaymentStatus,
          TIMEOUT_TIME
        );
      }
    };

    const fetchFlyxBooking = async () => {
      if (!flyxBookingKey) return;

      flyxBookingFetchCount += 1;

      const { data: res } = await getFlightBookingDetail({
        bookingKey: flyxBookingKey,
      });

      if (res.code === 200) {
        setFlyxBooking(res?.data);
        cookie.set(CURRENCY_CODE, res?.data?.payment?.currency?.code || 'VND');
        cookie.set(CURRENCY_PAY, res?.data?.payment?.currency?.rate || '1.0');

        const bookingStatus = res.data?.booking?.bookingStatus;
        if (bookingStatus === flyxBookingStatus.SUCCESS) {
          setScreenStatus(LIST_SCREEN_STATUS.SUCCESS);
        } else if (bookingStatus === flyxBookingStatus.FAILED) {
          if (paymentMethod === flightPaymentMethodCode.HOLDING) {
            setScreenStatus(LIST_SCREEN_STATUS.HOLDING_FAIL);
          } else {
            setScreenStatus(LIST_SCREEN_STATUS.BOOKING_FAIL);
          }
        } else if (bookingStatus === flyxBookingStatus.HOLDING) {
          if (paymentMethod === flightPaymentMethodCode.HOLDING) {
            setScreenStatus(LIST_SCREEN_STATUS.HOLDING_SUCCESS);
          } else if (
            paymentMethod === flightPaymentMethodCode.BANK_TRANSFER_2
          ) {
            setScreenStatus(LIST_SCREEN_STATUS.WAITING_PAYMENT);
          } else if (
            paymentMethod === flightPaymentMethodCode.CRYPTO_TRANSFER
          ) {
            setScreenStatus(paymentMethod);
            fetchPaymentStatusTimeOutRef.current = setTimeout(
              fetchPaymentStatus,
              TIME_CALL_API_STATUS
            );
          } else {
            handleSetTimeOutFlyxBooking(bookingStatus);
          }
        } else if (
          bookingStatus === flyxBookingStatus.WAITING_PAYMENT &&
          paymentMethod === flightPaymentMethodCode.BANK_TRANSFER_2
        ) {
          setScreenStatus(LIST_SCREEN_STATUS.WAITING_PAYMENT);
        } else if (
          bookingStatus === flyxBookingStatus.IN_PROGRESS ||
          bookingStatus === flyxBookingStatus.WAITING_PAYMENT
        ) {
          if (paymentMethod === flightPaymentMethodCode.CRYPTO_TRANSFER) {
            setScreenStatus(paymentMethod);
            fetchPaymentStatusTimeOutRef.current = setTimeout(
              fetchPaymentStatus,
              TIME_CALL_API_STATUS
            );
          } else {
            handleSetTimeOutFlyxBooking(bookingStatus);
          }
        } else {
          setScreenStatus(LIST_SCREEN_STATUS.UNDEFINED);
        }
      } else toast.error(res.message);
    };

    const fetchPaymentStatus = async () => {
      paymentFetchCount += 1;

      const { data: res } = await checkingPaymentStatus({
        gatewayParams: location?.search?.substring(1) || '',
        transactionId: bookingId,
      });

      if (res.code === 200) {
        setPaymentStatusDetail(res?.data);

        const status = res.data?.transactionInfo?.status;
        paymentMethod = res.data?.transactionInfo?.paymentMethodInfo?.code;
        flyxBookingKey = res.data?.transactionInfo?.hashBookingId;

        if (status === paymentStatus.failed) {
          setScreenStatus(LIST_SCREEN_STATUS.FAILED);
          const { data: bookingDetailRes } = await getFlightBookingDetail({
            bookingKey: flyxBookingKey,
          });
          if (bookingDetailRes.code === 200) {
            setFlyxBooking(bookingDetailRes?.data);
          }
        } else if (
          status === paymentStatus.settled ||
          status === paymentStatus.holding ||
          (status === paymentStatus.awaiting_payment &&
            paymentMethod === flightPaymentMethodCode.BANK_TRANSFER_2) ||
          (status === paymentStatus.awaiting_payment &&
            paymentMethod === flightPaymentMethodCode.CRYPTO_TRANSFER)
        ) {
          fetchFlyxBooking();
        } else {
          handleSetTimeOutPaymentStatus();
        }
      } else toast.error(res.message);
    };

    const fetchAgentInfo = async () => {
      const { data: res } = await checkingAgentsInfo({
        transactionId: bookingId,
      });
      if (res.code === 200) {
        setAgentsInfo(res.data);
      }
    };

    if (bookingId) {
      fetchPaymentStatus();
      fetchAgentInfo();
    }

    return () => {
      if (fetchPaymentStatusTimeOutRef.current) {
        clearTimeout(fetchPaymentStatusTimeOutRef.current);
      }

      if (fetchFlyxBookingTimeOutRef.current) {
        clearTimeout(fetchFlyxBookingTimeOutRef.current);
      }
    };
  }, [bookingId, location?.search]);

  useEffect(() => {
    pushGtm(flyxBooking, paymentStatusDetail, agentsInfo);
  }, [agentsInfo, flyxBooking, paymentStatusDetail]);

  return {
    screenStatus,
    flyxBooking,
    paymentStatusDetail,
    agentsInfo,
  };
};

export default useFlightBooking;
